import { Box, Button, Typography } from "@material-ui/core";
import FlexBox from "components/FlexBox";
import React from "react";
import use100KStyles from "../HundredK.styles";

export enum ButtonType {
  Purple = "purple",
  White = "white",
  Gradient = "",
}

interface IProps {
  children: any;
  onClick: () => void;
  type?: ButtonType;
  disabledPadding?: boolean;
  disabledMarginBottom?: boolean;
}

const HundredKButton = ({
  children,
  onClick,
  type = ButtonType.Gradient,
  disabledPadding,
  disabledMarginBottom,
}: IProps) => {
  const classes = use100KStyles();

  return (
    <FlexBox
      flexDirection="row"
      justification="center"
      alignment="middle"
      style={{
        position: "relative",
        padding: disabledPadding ? undefined : "0 20px",
      }}
      onClick={onClick}
    >
      <img
        style={{ width: "100%" }}
        src={`${process.env.PUBLIC_URL}/100k-assets/${
          type !== ButtonType.Gradient ? `${type}-` : ""
        }button.png`}
      />
      <Typography
        className={classes.buttonText}
        style={{
          position: "absolute",
          marginBottom: disabledMarginBottom ? undefined : "10px",
        }}
      >
        {children}
      </Typography>
    </FlexBox>
  );
};

export default HundredKButton;
