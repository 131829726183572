import { Box, Typography } from "@material-ui/core";
import FlexBox from "components/FlexBox";
import Spacer from "components/Spacer";
import React from "react";
import { useNavigate } from "react-router";
import { toDollarString } from "utils/numbers";
import HundredKButton, { ButtonType } from "./components/HundredKButton";
import use100KStyles from "./HundredK.styles";

interface IProps {
  dollars: number;
}

const HundredKFacebookScreen = ({ dollars }: IProps) => {
  const classes = use100KStyles();
  const navigate = useNavigate();

  const total = dollars / 1.4829;

  return (
    <FlexBox
      width="100%"
      flexDirection="column"
      justification="left"
      alignment="top"
      padding="0 20px"
    >
      <Typography
        className={classes.linkText}
        style={{
          color: "#FFFFFF",
          textAlign: "left",
          textDecoration: "none",
        }}
        onClick={() => navigate("/100k/invest")}
      >
        &lt;- Choose other investment
      </Typography>
      <Spacer height={44} />
      <Typography className={classes.heading3}>
        Uh Oh! If you invest in Facebook we project you'll have:
      </Typography>
      <Spacer height={24} />
      <FlexBox
        flexDirection="column"
        justification="center"
        alignment="top"
        padding="32px 0"
        width="100%"
        style={{
          background: "rgba(239, 70, 111, 0.13)",
          borderRadius: "12px",
        }}
      >
        <Typography className={classes.highlight} style={{ color: "#EF466F" }}>
          ${toDollarString(total)}
        </Typography>
        <Spacer height={16} />
        <Typography className={classes.buttonText}>by 2055</Typography>
      </FlexBox>
      <Spacer height={24} />
      <HundredKButton
        onClick={() => navigate("/100k/invest")}
        type={ButtonType.White}
        disabledMarginBottom
      >
        Choose again
      </HundredKButton>
      <Spacer height={48} />
      <Box
        style={{
          width: "172px",
          height: "172px",
          background: `url("${process.env.PUBLIC_URL}/100k-assets/facebook.gif")`,
          borderRadius: "50%",
          backgroundSize: "cover",
          backgroundPosition: "center",
          margin: "auto",
        }}
      />
      <Spacer height={24} />
      <Typography className={classes.body} style={{ color: "#CECAD7" }}>
        As of September 2022, Facebook is down{" "}
        <Box component="span" style={{ color: "#D11A2A", fontWeight: 700 }}>
          -6%
        </Box>{" "}
        over the last 5 years. Recently, Facebook has struggled to increase
        their user base and has seen their stock slide as a result. As of
        September 2022, Facebook is down
      </Typography>
      <Spacer height={72} />
      <Typography className={classes.footnote} style={{ color: "#B1AEB7" }}>
        To project your return in 2055, the compound interest rate of return is
        assumed to be -1.2% compounded monthly to proxy the -6% five-year
        historical return of Meta stock as of September 7, 2022. This example is
        intended to be hypothetical and used for illustrative purposes only.
        Market conditions can and will impact performance. Investing involves
        risk of loss and performance is not guaranteed.
      </Typography>
      <Spacer height={24} />
    </FlexBox>
  );
};

export default HundredKFacebookScreen;
