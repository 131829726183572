import { Box } from "@material-ui/core";
import React from "react";

interface IProps {
  children: any;
  flexDirection: "row" | "row-reverse" | "column" | "column-reverse";
  justification: "left" | "center" | "right";
  alignment: "top" | "middle" | "bottom";
  width?: any;
  padding?: string;
  className?: any;
  style?: any;
  onClick?: () => void;
}

const getJustification = (
  flexDirection: "row" | "row-reverse" | "column" | "column-reverse",
  justification: "left" | "center" | "right"
) => {
  const property = flexDirection.startsWith("row")
    ? "justifyContent"
    : "alignItems";
  let value;

  switch (justification) {
    case "left":
      value = "flex-start";
      break;
    case "center":
      value = "center";
      break;
    case "right":
      value = "flex-end";
      break;
  }

  return { [property]: value };
};

const getAlignment = (
  flexDirection: "row" | "row-reverse" | "column" | "column-reverse",
  alignment: "top" | "middle" | "bottom"
) => {
  const property = flexDirection.startsWith("row")
    ? "alignItems"
    : "justifyContent";
  let value;

  switch (alignment) {
    case "top":
      value = "flex-start";
      break;
    case "middle":
      value = "center";
      break;
    case "bottom":
      value = "flex-end";
      break;
  }

  return { [property]: value };
};

const FlexBox = ({
  children,
  flexDirection,
  justification,
  alignment,
  width,
  padding,
  className,
  style,
  onClick,
}: IProps) => {
  const styles = {
    display: "flex",
    flexDirection: flexDirection,
    ...getJustification(flexDirection, justification),
    ...getAlignment(flexDirection, alignment),
    width,
    padding,
    ...style,
  };

  return (
    <Box style={styles} className={className} onClick={onClick}>
      {children}
    </Box>
  );
};

export default FlexBox;
