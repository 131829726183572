import { Box, Typography } from "@material-ui/core";
import FlexBox from "components/FlexBox";
import Spacer from "components/Spacer";
import React from "react";
import { useNavigate } from "react-router";
import { toDollarString } from "utils/numbers";
import HundredKButton, { ButtonType } from "./components/HundredKButton";
import use100KStyles from "./HundredK.styles";

interface IProps {
  dollars: number;
}

const HundredKSavingsScreen = ({ dollars }: IProps) => {
  const classes = use100KStyles();
  const navigate = useNavigate();

  const total = dollars * 1.044;

  return (
    <FlexBox
      width="100%"
      flexDirection="column"
      justification="left"
      alignment="top"
      padding="0 20px"
    >
      <Typography
        className={classes.linkText}
        style={{
          color: "#FFFFFF",
          textAlign: "left",
          textDecoration: "none",
        }}
        onClick={() => navigate("/100k/invest")}
      >
        &lt;- Choose other investment
      </Typography>
      <Spacer height={44} />
      <Typography className={classes.heading3}>
        Meh. If you put your cash in a savings account, we project you’ll have:
      </Typography>
      <Spacer height={24} />
      <FlexBox
        flexDirection="column"
        justification="center"
        alignment="top"
        padding="32px 0"
        width="100%"
        style={{
          background: "rgba(254, 203, 62, 0.1)",
          borderRadius: "12px",
        }}
      >
        <Typography className={classes.highlight} style={{ color: "#FECB3E" }}>
          ${toDollarString(total)}
        </Typography>
        <Spacer height={16} />
        <Typography className={classes.buttonText}>by 2055</Typography>
      </FlexBox>
      <Spacer height={24} />
      <HundredKButton
        onClick={() => navigate("/100k/invest")}
        type={ButtonType.White}
        disabledMarginBottom
      >
        Choose again
      </HundredKButton>
      <Spacer height={48} />
      <Box
        style={{
          width: "172px",
          height: "172px",
          background: `url("${process.env.PUBLIC_URL}/100k-assets/savings_account.gif")`,
          borderRadius: "50%",
          backgroundSize: "cover",
          backgroundPosition: "center",
          margin: "auto",
        }}
      />
      <Spacer height={24} />
      <Typography className={classes.body} style={{ color: "#CECAD7" }}>
        As of September 2022, the average savings account is earning a{" "}
        <Box component="span" style={{ color: "#4FE382", fontWeight: 700 }}>
          +0.13%
        </Box>{" "}
        annual return. You have the potential to earn a lot more by investing it
        in the stock market.
      </Typography>
      <Spacer height={72} />
      <Typography className={classes.footnote} style={{ color: "#B1AEB7" }}>
        Compound interest rate of return is assumed to be 0.07% compounded
        monthly and is equivalent to the national average savings account
        interest rate as of September 5, 2022.
      </Typography>
      <Spacer height={24} />
    </FlexBox>
  );
};

export default HundredKSavingsScreen;
