import { Box, Typography } from "@material-ui/core";
import FlexBox from "components/FlexBox";
import Spacer from "components/Spacer";
import React from "react";
import HundredKButton from "./components/HundredKButton";
import HundredKHeader from "./components/HundredKHeader";
import use100KStyles from "./HundredK.styles";

const HundredKSummaryScreen = () => {
  const classes = use100KStyles();

  return (
    <FlexBox flexDirection="column" justification="center" alignment="top">
      <HundredKHeader />
      <Spacer height={24} />
      <FlexBox
        flexDirection="column"
        justification="center"
        alignment="top"
        padding="0 20px"
      >
        <Box>
          <img src={`${process.env.PUBLIC_URL}/100k-assets/scout-logo.png`} />
        </Box>
        <Spacer height={24} />
        <Typography className={classes.heading3}>
          Build Your Own “S&P” With Scout.
        </Typography>
        <Spacer height={24} />
        <FlexBox
          flexDirection="column"
          justification="center"
          alignment="top"
          style={{ background: "#1B142F", padding: "24px 20px" }}
        >
          <img src={`${process.env.PUBLIC_URL}/100k-assets/heart.png`} />
          <Spacer height={12} />
          <Typography className={classes.caption}>
            only things you like
          </Typography>
          <Spacer height={12} />
          <Typography className={classes.body} style={{ color: "#CECAD7" }}>
            Receive a personalized portfolio made up of stocks tied to your
            interests.
          </Typography>
        </FlexBox>
        <Spacer height={12} />
        <FlexBox
          flexDirection="column"
          justification="center"
          alignment="top"
          style={{ background: "#1B142F", padding: "24px 20px" }}
        >
          <img src={`${process.env.PUBLIC_URL}/100k-assets/lightbulb.png`} />
          <Spacer height={12} />
          <Typography className={classes.caption}>
            Automatically diversified
          </Typography>
          <Spacer height={12} />
          <Typography className={classes.body} style={{ color: "#CECAD7" }}>
            Your 4000+ stock portfolio will ready you to take on the market in
            any condition.
          </Typography>
        </FlexBox>
        <Spacer height={12} />
        <FlexBox
          flexDirection="column"
          justification="center"
          alignment="top"
          style={{ background: "#1B142F", padding: "24px 20px" }}
        >
          <img src={`${process.env.PUBLIC_URL}/100k-assets/trophy.png`} />
          <Spacer height={12} />
          <Typography className={classes.caption}>
            Actively managed for you
          </Typography>
          <Spacer height={12} />
          <Typography className={classes.body} style={{ color: "#CECAD7" }}>
            Monitored daily by our team of investment professionals.
          </Typography>
        </FlexBox>
      </FlexBox>
      <Spacer height={48} />
      <HundredKButton onClick={() => {}}>Start investing</HundredKButton>
      <Spacer height={32} />
    </FlexBox>
  );
};

export default HundredKSummaryScreen;
