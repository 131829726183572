import { Box, Typography } from "@material-ui/core";
import React from "react";
import FlexBox from "components/FlexBox";
import use100KStyles from "../HundredK.styles";
import Spacer from "components/Spacer";

interface IProps {
  dollars?: number;
}

const HundredKHeader = ({ dollars }: IProps) => {
  const classes = use100KStyles();

  return (
    <FlexBox
      flexDirection="row"
      justification="left"
      alignment="middle"
      width="100vw"
      padding="20px"
    >
      <Box style={{ flex: 1, textAlign: "left" }}>
        <img src={`${process.env.PUBLIC_URL}/Logo.svg`} />
      </Box>
      {dollars !== undefined && (
        <FlexBox flexDirection="row" justification="left" alignment="middle">
          <img src={`${process.env.PUBLIC_URL}/100k-assets/dollar-bill.svg`} />
          <Spacer width={8} />
          <Typography className={classes.buttonText}>{dollars}</Typography>
        </FlexBox>
      )}
    </FlexBox>
  );
};

export default HundredKHeader;
