import {
  BrowserRouter,
  Navigate,
  Route,
  Routes as Switch,
} from "react-router-dom";
import HundredKModule from "views/HundredK/HundredKModule";
import HundredKIntroductionScreen from "views/HundredK/IntroductionScreen/HundredKIntroductionScreen";
import HundredKSummaryScreen from "views/HundredK/SummaryScreen";

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="100k" element={<HundredKIntroductionScreen />}></Route>
        <Route path="/100k/summary" element={<HundredKSummaryScreen />}></Route>
        <Route path="/100k/*" element={<HundredKModule />}></Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
