import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Routes from "routes";
import { Box, createTheme, ThemeProvider } from "@material-ui/core";

const theme = createTheme({});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Box
        display="flex"
        flexDirection="column"
        style={{
          width: "100vw",
          height: "100vh",
          backgroundColor: "#0C0026",
          overflow: "auto",
        }}
        className="app"
      >
        <Routes />
      </Box>
    </ThemeProvider>
  );
}

export default App;
