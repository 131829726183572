import { Box } from "@material-ui/core";
import FlexBox from "components/FlexBox";
import React from "react";

interface IProps {
  checked: boolean;
}

const HundredKCheckbox = ({ checked }: IProps) => {
  return (
    <FlexBox
      flexDirection="row"
      justification="center"
      alignment="middle"
      style={{
        width: "40px",
        height: "40px",
        background: checked ? "#4FE382" : "#373246",
        borderRadius: 8,
      }}
    >
      {checked && (
        <img src={`${process.env.PUBLIC_URL}/100k-assets/check.svg`} />
      )}
    </FlexBox>
  );
};

export default HundredKCheckbox;
