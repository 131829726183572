import { Box, Typography } from "@material-ui/core";
import FlexBox from "components/FlexBox";
import Spacer from "components/Spacer";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import HundredKButton, { ButtonType } from "../components/HundredKButton";
import use100KStyles from "../HundredK.styles";

interface Link {
  url: string;
  text: string;
}

const links = [
  {
    url: "/100k/amc",
    text: "Invest in AMC",
  },
  {
    url: "/100k/facebook",
    text: "Invest in Facebook",
  },
  {
    url: "/100k/sp500",
    text: "Invest in S&P 500",
  },
  {
    url: "/100k/savings",
    text: "Put it in savings",
  },
];

const HundredKInvestScreen = () => {
  const classes = use100KStyles();
  const navigate = useNavigate();

  return (
    <FlexBox
      width="100%"
      flexDirection="column"
      justification="left"
      alignment="top"
      padding="0 20px"
    >
      <Typography
        className={classes.linkText}
        style={{
          color: "#FFFFFF",
          textAlign: "left",
          textDecoration: "none",
        }}
        onClick={() => navigate("/100k/save")}
      >
        &lt;- Collect more cash
      </Typography>
      <Spacer height={44} />
      <Typography className={classes.heading3}>
        Where would you like to{" "}
        <Box component="span" className={classes.gradient}>
          invest your extra cash
        </Box>
        ?
      </Typography>
      <Spacer height={24} />
      <Typography
        className={classes.body}
        style={{ textAlign: "center", width: "100%" }}
      >
        Pick your investment vehicle.
      </Typography>
      <Spacer height={24} />
      {links.map((link) => (
        <>
          <HundredKButton
            key={link.url}
            onClick={() => {
              navigate(link.url);
            }}
            type={ButtonType.Purple}
            disabledPadding={true}
            disabledMarginBottom={true}
          >
            {link.text}
          </HundredKButton>
          <Spacer height={8} key={link.url + "-spacer"} />
        </>
      ))}
    </FlexBox>
  );
};

export default HundredKInvestScreen;
