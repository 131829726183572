import { Box, Typography } from "@material-ui/core";
import FlexBox from "components/FlexBox";
import Spacer from "components/Spacer";
import { HundredKSaveItem } from "models/HundredKSaveItem";
import React from "react";
import use100KStyles from "../HundredK.styles";
import HundredKCheckbox from "./HundredKCheckbox";

interface IProps {
  item: HundredKSaveItem;
  checked: boolean;
  onClick: (item: HundredKSaveItem) => void;
}

const HundredKSaveCard = ({ item, checked, onClick }: IProps) => {
  const classes = use100KStyles();

  return (
    <FlexBox
      flexDirection="column"
      justification="center"
      alignment="top"
      width="100%"
      style={{
        background: "#211B32",
        border: "1px solid #000000",
        backdropFilter: "blur(80px)",
        borderRadius: "12px",
        padding: "32px",
        position: "relative",
      }}
      onClick={() => onClick(item)}
    >
      <Box style={{ position: "absolute", top: 20, right: 20 }}>
        <HundredKCheckbox checked={checked} />
      </Box>
      <FlexBox
        flexDirection="column"
        justification="center"
        alignment="middle"
        style={{ height: 200, width: "100%" }}
      >
        <img style={{ height: "90%" }} src={item.imgUrl} />
      </FlexBox>
      <Spacer height={16} />
      <Typography className={classes.body}>{item.description}</Typography>
      <Spacer height={12} />
      <FlexBox flexDirection="row" justification="center" alignment="middle">
        <img
          style={{ width: 30 }}
          src={`${process.env.PUBLIC_URL}/100k-assets/dollar-bill.svg`}
        />
        <Spacer width={8} />
        <Typography className={classes.caption}>{item.dollars}</Typography>
      </FlexBox>
    </FlexBox>
  );
};

export default HundredKSaveCard;
