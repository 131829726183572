import FlexBox from "components/FlexBox";
import React from "react";
import { Route, Routes } from "react-router";
import HundredKAMCScreen from "./AMCScreen";
import HundredKHeader from "./components/HundredKHeader";
import HundredKFacebookScreen from "./FacebookScreen";
import useHundredKModule from "./HundredKModule.hooks";
import HundredKInvestScreen from "./InvestScreen/HundredKInvestScreen";
import HundredKSaveScreen from "./SaveScreen/HundredKSaveScreen";
import HundredKSavingsScreen from "./SavingsScreen";
import HundredKSP500Screen from "./SP500Screen";
import HundredKSummaryScreen from "./SummaryScreen";

const HundredKModule = () => {
  const { dollars, items, saved, toggleSave } = useHundredKModule();

  return (
    <FlexBox
      width="100%"
      flexDirection="column"
      justification="center"
      alignment="top"
    >
      <HundredKHeader dollars={dollars} />
      <Routes>
        <Route
          path="save"
          element={
            <HundredKSaveScreen
              items={items}
              saved={saved}
              toggleSave={toggleSave}
            />
          }
        />
        <Route path="invest" element={<HundredKInvestScreen />} />
        <Route path="amc" element={<HundredKAMCScreen dollars={dollars} />} />
        <Route
          path="facebook"
          element={<HundredKFacebookScreen dollars={dollars} />}
        />
        <Route
          path="savings"
          element={<HundredKSavingsScreen dollars={dollars} />}
        />
        <Route
          path="sp500"
          element={<HundredKSP500Screen dollars={dollars} />}
        />
      </Routes>
    </FlexBox>
  );
};

export default HundredKModule;
