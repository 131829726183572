import { makeStyles } from "@material-ui/core";

const use100KStyles = makeStyles({
  root: {
    color: "#fff",
    background: `url('${process.env.PUBLIC_URL}/100k-assets/background.svg')`,
    backgroundSize: "cover",
    width: "100vw",
    height: "100vh",
  },
  highlight: {
    fontFamily: "Pixel NES",
    fontWeight: 400,
    fontSize: "60px",
    lineHeight: "60px",
  },
  heading1: {
    fontFamily: "Pixel NES",
    fontWeight: 400,
    fontSize: "48px",
    lineHeight: "60px",
  },
  heading3: {
    fontFamily: "Pixel NES",
    fontWeight: 400,
    fontSize: "28px",
    lineHeight: "32px",
  },
  buttonText: {
    fontFamily: "Pixel NES",
    fontWeight: 400,
    fontSize: "24px",
    lineHeight: "24px",
  },
  gradient: {
    background:
      "-webkit-linear-gradient(91.26deg, #8AD4EC 0%, #EF96FF 21.74%, #FF56A9 54.03%, #FFAA6C 85.28%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  body: {
    fontFamily: "SF Pro",
    fontWeight: 400,
    fontSize: "17px",
    lineHeight: "22px",
  },
  caption: {
    fontFamily: "Pixel NES",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "18px",
  },
  linkText: {
    fontFamily: "Pixel NES",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "18px",
  },
  footnote: {
    fontFamily: "SF Pro",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "18px",
  }
});

export default use100KStyles;
