import { Box, Typography } from "@material-ui/core";
import FlexBox from "components/FlexBox";
import Spacer from "components/Spacer";
import React from "react";
import { useNavigate } from "react-router-dom";
import { gray4 } from "utils/colors";
import HundredKButton from "../components/HundredKButton";
import HundredKHeader from "../components/HundredKHeader";
import use100KStyles from "../HundredK.styles";

const HundredKIntroductionScreen = () => {
  const classes = use100KStyles();
  const navigate = useNavigate();

  return (
    <FlexBox
      flexDirection="column"
      justification="center"
      alignment="top"
      className={classes.root}
    >
      <HundredKHeader />
      <Box style={{ padding: "20px", flex: 1 }}>
        <Typography className={classes.heading1}>Collect your way,</Typography>
        <Typography className={`${classes.heading1} ${classes.gradient}`}>
          zero to $100k
        </Typography>
        <Spacer height={24} />
        <Typography className={classes.body} style={{ color: gray4 }}>
          The game where you find extra money to save, invest it, and build your
          passive income empire without batting an eye.
        </Typography>
      </Box>
      <HundredKButton onClick={() => navigate("/100k/save")}>
        Start Collecting
      </HundredKButton>
      <Spacer height={24} />
    </FlexBox>
  );
};

export default HundredKIntroductionScreen;
