import { Box, Typography } from "@material-ui/core";
import FlexBox from "components/FlexBox";
import Spacer from "components/Spacer";
import { HundredKSaveItem } from "models/HundredKSaveItem";
import React from "react";
import { useNavigate } from "react-router";
import HundredKButton from "../components/HundredKButton";
import HundredKHeader from "../components/HundredKHeader";
import use100KStyles from "../HundredK.styles";
import HundredKSaveCard from "./HundredKSaveCard";

interface IProps {
  items: HundredKSaveItem[];
  saved: any;
  toggleSave: (item: HundredKSaveItem) => void;
}

const HundredKSaveScreen = ({ items, saved, toggleSave }: IProps) => {
  const classes = use100KStyles();
  const navigate = useNavigate();

  return (
    <FlexBox
      width="100%"
      flexDirection="column"
      justification="center"
      alignment="top"
    >
      <Spacer height={24} />
      <Box style={{ padding: "0 20px" }}>
        <Typography className={classes.heading3}>
          Start by collecting some
        </Typography>
        <Typography className={`${classes.heading3} ${classes.gradient}`}>
          extra cash.
        </Typography>
        <Spacer height={24} />
        <Typography className={classes.body} style={{ color: "#CECAD7" }}>
          Forget working a fancy job to find extra cash to invest every month.
          We have better ideas.
        </Typography>
      </Box>
      <Spacer height={24} />
      <Box style={{ width: "100%", padding: "0 20px" }}>
        {items.map((item) => (
          <>
            <HundredKSaveCard
              key={item.id}
              item={item}
              checked={!!saved[item.id]}
              onClick={toggleSave}
            />
            <Spacer key={item.id + "-spacer"} height={12} />
          </>
        ))}
      </Box>
      <Spacer height={100} />
      {!!Object.keys(saved).length && (
        <Box style={{ position: "fixed", bottom: 0 }}>
          <HundredKButton onClick={() => navigate("/100k/invest")}>
            Invest your cash
          </HundredKButton>
          <Spacer height={24} />
        </Box>
      )}
    </FlexBox>
  );
};

export default HundredKSaveScreen;
