import { Box, Typography } from "@material-ui/core";
import FlexBox from "components/FlexBox";
import Spacer from "components/Spacer";
import React from "react";
import { useNavigate } from "react-router";
import { toDollarString } from "utils/numbers";
import HundredKButton, { ButtonType } from "./components/HundredKButton";
import use100KStyles from "./HundredK.styles";

interface IProps {
  dollars: number;
}

const HundredKSP500Screen = ({ dollars }: IProps) => {
  const classes = use100KStyles();
  const navigate = useNavigate();

  const total = dollars * 39.68;

  return (
    <FlexBox
      width="100%"
      flexDirection="column"
      justification="left"
      alignment="top"
      padding="0 20px"
    >
      <Typography
        className={classes.linkText}
        style={{
          color: "#FFFFFF",
          textAlign: "left",
          textDecoration: "none",
        }}
        onClick={() => navigate("/100k/invest")}
      >
        &lt;- Choose other investment
      </Typography>
      <Spacer height={44} />
      <Typography className={classes.heading3}>
        CHA CHING! If you invest in THE S&P 500, we project you’ll have:
      </Typography>
      <Spacer height={24} />
      <FlexBox
        flexDirection="column"
        justification="center"
        alignment="top"
        padding="32px 0"
        width="100%"
        style={{
          background: "rgba(79, 227, 130, 0.1)",
          borderRadius: "12px",
        }}
      >
        <Typography className={classes.highlight} style={{ color: "#4FE382" }}>
          ${toDollarString(total)}
        </Typography>
        <Spacer height={16} />
        <Typography className={classes.buttonText}>by 2055</Typography>
      </FlexBox>
      <Spacer height={48} />
      <Box
        style={{
          width: "172px",
          height: "172px",
          background: `url("${process.env.PUBLIC_URL}/100k-assets/S&p.gif")`,
          borderRadius: "50%",
          backgroundSize: "200px",
          backgroundPosition: "center",
          margin: "auto",
        }}
      />
      <Spacer height={24} />
      <Typography className={classes.body} style={{ color: "#CECAD7" }}>
        As of September 2022, the S&P 500 is up{" "}
        <Box component="span" style={{ color: "#4FE382", fontWeight: 700 }}>
          +60%
        </Box>{" "}
        over the last 5 years.
      </Typography>
      <Spacer height={72} />
      <Typography className={classes.buttonText}>
        What Is the S&P 500?
      </Typography>
      <Spacer height={24} />
      <Typography className={classes.body} style={{ color: "#CECAD7" }}>
        The S&P 500 is an index fund containing the 500 most representative
        stocks in the U.S. economy.
      </Typography>
      <Spacer height={24} />
      <Box>
        <img
          src={`${process.env.PUBLIC_URL}/100k-assets/logos.png`}
          style={{ width: "75%" }}
        />
      </Box>
      <Spacer height={72} />
      <Typography className={classes.buttonText}>
        How is investing in the S&p different than single stocks?
      </Typography>
      <Spacer height={24} />
      <Typography className={classes.body} style={{ color: "#CECAD7" }}>
        By investing in the S&P 500, your investment is safer and more reliable
        because you’re investing in 500 companies vs. 1. This is known as the
        concept of diversification.
      </Typography>
      <Spacer height={8} />
      <Typography className={classes.body} style={{ color: "#CECAD7" }}>
        Fun Fact: The S&P has returned{" "}
        <Box component="span" style={{ color: "#4FE382", fontWeight: 700 }}>
          +11.8%
        </Box>{" "}
        per year on average since its inception in 1957.
      </Typography>
      <Spacer height={72} />
      <Typography className={classes.footnote} style={{ color: "#B1AEB7" }}>
        To project your return in 2055, the compound interest rate of return is
        assumed to be 11.8% compounded monthly which is the average annual
        return of the S&P 500 since its inception in 1957. This example is
        intended to be hypothetical and used for illustrative purposes only.
        Market conditions can and will impact performance. Investing involves
        risk of loss and performance is not guaranteed.
      </Typography>
      <Spacer height={44} />
      <HundredKButton onClick={() => navigate("/100k/summary")}>Continue</HundredKButton>
      <Spacer height={44} />
    </FlexBox>
  );
};

export default HundredKSP500Screen;
