import { cloneDeep, sum, values } from "lodash";
import { HundredKSaveItem } from "models/HundredKSaveItem";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

const items = [
  {
    path: "1_quarter.gif",
    description: "Pick up a dirty quarter from the street",
    dollars: 0.25,
  },
  {
    path: "2_dollar.png",
    description: "Ask Mom to give you a dollar",
    dollars: 1,
  },
  {
    path: "3_parking.png",
    description: "Don't pay the parking meter, risk the ticket",
    dollars: 3,
  },
  {
    path: "4_receipt.png",
    description: "Use a coupon from your 9-foot-long CVS receipt",
    dollars: 4.5,
  },
  {
    path: "5_mcflurry.png",
    description: "Eat 1 less McFlurry each week",
    dollars: 5,
  },
  {
    path: "6_fan.gif",
    description: "Turn down the AC three degrees",
    dollars: 7,
  },
  {
    path: "7_walkman.png",
    description: "Trade Spotify for your aunt's old Walkman",
    dollars: 10,
  },
  {
    path: "8_venmo.png",
    description: "Decline your bestie's Venmo request",
    dollars: 10,
  },
  {
    path: "9_netflix.gif",
    description: "Steal your friend's Netflix password",
    dollars: 15,
  },
  {
    path: "10_bidet.png",
    description: "Trade toilet paper for a bidet",
    dollars: 12,
  },
  {
    path: "11_harrypotter.png",
    description:
      "Skip a trip to the movie theater and watch Harry Potter reruns on ABC",
    dollars: 15,
  },
  {
    path: "11_smoke.gif",
    description: "Give up your vape and pantomime smoking instead",
    dollars: 35,
  },
  {
    path: "13_soberfeb.png",
    description: "Sober February (still pretend you're drunk)",
    dollars: 40,
  },
  {
    path: "14_goodwill.png",
    description: "Find some cool vintage stuff at Goodwill",
    dollars: 50,
  },
  {
    path: "15_lunchable.png",
    description: "Pick ham and cheese Lunchables for lunch everyday",
    dollars: 75,
  },
  {
    path: "16_hawaii.png",
    description: "Trade Hawaii for a staycation with VR glasses",
    dollars: 100,
  },
  {
    path: "17_yeezy.png",
    description: "Trade my Yeezys on StockX",
    dollars: 125,
  },
  {
    path: "18_coachella.png",
    description: "Watch Coachella via Livestream",
    dollars: 400,
  },
  {
    path: "19_holidays.gif",
    description: "Tell your family they can spend the holidays alone",
    dollars: 500,
  },
  {
    path: "20_wedding.png",
    description:
      "Turn down a wedding invite for a family member you don't care about",
    dollars: 1000,
  },
  {
    path: "21_scout.png",
    description: "Refer 10 friends to Scout",
    dollars: 50,
  },
];

const getItems = (): HundredKSaveItem[] => {
  return items.map((item) => ({
    ...item,
    id: uuidv4(),
    imgUrl: `${process.env.PUBLIC_URL}/100k-assets/graphics/${item.path}`,
  }));
};

const useHundredKModule = () => {
  const [saved, setSaved] = useState<any>({});
  const [items, setItems] = useState<HundredKSaveItem[]>([]);

  const calculateDollars = () => {
    return sum(values(saved).map((item) => item.dollars));
  };

  useEffect(() => {
    setItems(getItems());
  }, []);

  console.log(saved);

  const toggleSave = (item: HundredKSaveItem) => {
    if (saved[item.id]) {
      delete saved[item.id];
    } else {
      saved[item.id] = item;
    }

    setSaved(cloneDeep(saved));
  };

  return { dollars: calculateDollars(), items, saved, toggleSave };
};

export default useHundredKModule;
